import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AuthProvider } from '../contexts/useAuth'
import Login from './auth/login'
import Convites from './convites'
import MeusDados from './meus-dados'
import Veterinarios from './veterinarios'

export default function Router() {
    return (
        <BrowserRouter>
                <Switch>
                    <AuthProvider>
                        <Route path="/" exact component={Veterinarios}/>
                        <Route path="/dados" exact component={MeusDados}/>
                        <Route path="/convites" exact component={Convites}/>
                        <Route path="/login" exact component={Login}/>
                    </AuthProvider>
                </Switch>
        </BrowserRouter>
    )
}
