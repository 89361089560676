import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Input({ name, className, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return <input className={`input ${className || ''}`} ref={inputRef} id={fieldName} name={fieldName} defaultValue={defaultValue} {...rest} />;
}
