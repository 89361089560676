import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';


const Select = ({ name, options, className, children, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return <select className={`input ${className || ''}`} ref={selectRef} id={fieldName} name={fieldName} defaultValue={defaultValue} {...rest}>
        {
            options ? options.map(option => (<option selected={option.value === defaultValue} key={option.value} value={option.value}>{option.label}</option>)) : children
        }
    </select>;
};

export default Select;
