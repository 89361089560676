import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ClipLoader  } from 'react-spinners'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import useAuth from '../../contexts/useAuth'
import './login.scss'

export default function Login() {
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const emailRef = useRef()
    const senhaRef = useRef()

    const { login } = useAuth()

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const email = emailRef.current.value;
        const senha = senhaRef.current.value;

        (async () => {
            const { status } = await login(email, senha)
            setLoading(false)
            if (status === 204) return toastr.error('Email e/ou senha incorretos.');
            if (status !== 200) return toastr.error('Aconteceu um erro inesperado.')

            return history.push('/')
        })()
    }

    return (
        <div className="d-flex-column flex-center login">
            <div className="brand">
                <img src="SistemaPET_logo.png" alt="SistemaPET" />
                <h1>Veterinários</h1>
            </div>
            <div className="desc">
                <h2>Entrar como criador</h2>
                <span>Insira seus dados para acessar sua conta</span>
            </div>
            <form className="d-flex-column" onSubmit={onSubmit}>
                <input required className="input" type="email" placeholder="E-mail" id="email" autoFocus={true} autoComplete="email" ref={emailRef} />
                <input required className="input" type="password" placeholder="Senha" id="senha" autoComplete="current-password" ref={senhaRef} />
                <div className="buttons">
                    <button className="flex-center btn btn-outline-secondary btn-rounded" style={{margin: '0'}} type="reset" onClick={e => {history.push('/')}}>Veterinários</button>
                    <button className="flex-center btn btn-primary btn-rounded" type="submit" disabled={loading}>{loading ? <ClipLoader size="12px" color="white"/> : "Entrar"}</button>
                </div>
            </form>
        </div>
    )
}
