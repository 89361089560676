import React, { useState } from 'react'
import Header from '../../components/Header'
import { Form } from '@unform/web';
import Input from '../../components/Input';
import useAuth from '../../contexts/useAuth';
import Select from '../../components/Select';
import './index.scss';
import api from '../../services/api';
import toastr from 'toastr';
import { ClipLoader } from 'react-spinners';

export default function MeusDados() {

    const { user, setUser } = useAuth()

    const [loading, setLoading] = useState(false)

    async function onSubmit(formData) {
        setLoading(true)

        try {
            const {data} = await api.post('parceiro/editar', formData);

            if (data) {
                if (data.erro) return toastr.error(data.erro);
                if (data.Instagram) formData.InstagramID = data.Instagram.CodRede;
                if (data.Facebook) formData.FacebookID = data.Facebook.CodRede;
            }

            toastr.success('Dados alterados com sucesso.')
            setUser({ ...user, ...formData })
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <>
            <Header />
            <main className="meus-dados">
                <div className="card">
                    <Form onSubmit={onSubmit} initialData={user}>
                        <div className="title">Informações básicas</div>
                        <div className="input-group">
                            <label htmlFor="Nome">Nome</label>
                            <Input name="Nome" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Proprietario">Proprietário</label>
                            <Input name="Proprietario" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Email">E-mail</label>
                            <Input name="Email" required type="email" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="TipoPessoa">Tipo de pessoa</label>
                            <Select name="TipoPessoa" options={[{ value: 'F', label: 'Fisica' }, { value: 'J', label: 'Jurídica' }]} />
                        </div>
                        <div className="input-group">
                            <label htmlFor="CPF_CNPJ">CPF ou CNPJ</label>
                            <Input name="CPF_CNPJ" required type="text" />
                        </div>
                        <div className="title">Localização</div>
                        <div className="input-group">
                            <label htmlFor="CEP">CEP</label>
                            <Input name="CEP" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Estado">Estado</label>
                            <Select name="Estado" options={[{ "value": "AC", "label": "Acre" },{ "value": "AL","label": "Alagoas" },{ "value": "AP", "label": "Amapá" },{ "value": "AM", "label": "Amazonas" },{ "value": "BA", "label": "Bahia" },{ "value": "CE", "label": "Ceará" },{ "value": "DF", "label": "Distrito Federal" },{ "value": "ES", "label": "Espírito Santo" },{ "value": "GO", "label": "Goiás" },{ "value": "MA", "label": "Maranhão" },{ "value": "MT", "label": "Mato Grosso" },{ "value": "MS", "label": "Mato Grosso do Sul" },{ "value": "MG", "label": "Minas Gerais" },{ "value": "PA", "label": "Pará" },{ "value": "PB", "label": "Paraíba" },{ "value": "PR", "label": "Paraná" },{ "value": "PE", "label": "Pernambuco" },{ "value": "PI", "label": "Piauí" },{ "value": "RJ", "label": "Rio de Janeiro" },{ "value": "RN", "label": "Rio Grande do Norte" },{ "value": "RS", "label": "Rio Grande do Sul" },{ "value": "RO", "label": "Rondônia" },{ "value": "RR", "label": "Roraima" },{ "value": "SC", "label": "Santa Catarina" },{ "value": "SP", "label": "São Paulo" },{ "value": "SE", "label": "Sergipe" },{ "value": "TO", "label": "Tocantins" }]} />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Cidade">Cidade</label>
                            <Input name="Cidade" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Bairro">Bairro</label>
                            <Input name="Bairro" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Endereco">Endereço</label>
                            <Input name="Endereco" required type="text" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Numero">Numero</label>
                            <Input name="Numero" required type="text" />
                        </div>
                        <div className="title">Contato</div>
                        <div className="input-group">
                            <label htmlFor="Telefone">Telefone</label>
                            <Input name="Telefone" required type="text" placeholder="(DDD) 0000-0000" />
                        </div>
                        <div className="title">Social</div>
                        <div className="input-group">
                            <label htmlFor="DestinoURL">Site</label>
                            <Input name="DestinoURL" type="site" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Instagram">Instagram</label>
                            <Input name="Instagram" type="text" />
                            <Input name="InstagramID" hidden type="number" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="Facebook">Facebook</label>
                            <Input name="Facebook" type="text" />
                            <Input name="FacebookID" hidden type="number" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-rounded" disabled={loading}>{loading ? <ClipLoader size="12px" color="white"/> : "Salvar"}</button>
                    </Form>
                </div>
            </main>
        </>
    )
}
