import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import Header from '../../components/Header'
import useAuth from '../../contexts/useAuth'
import api from '../../services/api'
import TextClamp from 'react-string-clamp';

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function formatPhone(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return null
}

function formatCellPhone(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
    if (match) {
        return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`
    }
    return null
}

function formatURL(url, defaultHttps = true) {
    if (!url.match(/^http/g)) return `${defaultHttps ? 'https://' : 'http://'}${url}`;

    return url;
}

export default function Veterinarios() {

    const [vet, setVet] = useState([])
    const [loading, setLoading] = useState(false)
    const { user, loadingUser } = useAuth()

    const estadoRef = useRef()
    const cidadeRef = useRef()
    const bairroRef = useRef()

    const getVeterinarios = useCallback(
        async () => {
            setLoading(true)
            try {
                const data = {}

                if (estadoRef.current && estadoRef.current.value) data.estado = estadoRef.current.value;
                if (cidadeRef.current && cidadeRef.current.value) data.cidade = cidadeRef.current.value;
                if (bairroRef.current && bairroRef.current.value) data.bairro = bairroRef.current.value;

                const res = await api.get('/', { params: data })

                setVet(res.data && Array.isArray(res.data) ? res.data : [])
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        },
        [],
    )

    useEffect(() => {
        if (!loadingUser) getVeterinarios();
    }, [
        getVeterinarios,
        loadingUser
    ])

    if (loadingUser) return <>
        <Header />
        <main className="d-flex flex-center">
            <PulseLoader color="#65AAFF" />
        </main>
    </>;

    const htmlData = vet.map(veterinario => {
        return (
            <div className="card veterinario" key={veterinario.Id}>
                <div className="row">
                    <div className="info column">
                        <span>{veterinario.Nome}</span>
                        <span className="text-muted">{formatPhone(veterinario.Telefone)}</span>
                        <span className="text-muted">{formatCellPhone(veterinario.Whatsapp)}</span>
                        <a className="text-muted" href={formatURL(veterinario.Site, false)}>{veterinario.Site}</a>
                        <div className="sociais">
                            {veterinario.Facebook && (<a target="_blank" rel="noreferrer" href={validURL(veterinario.Facebook) ? formatURL(veterinario.Facebook) : `https://facebook.com/${veterinario.Facebook}`} title="Facebook"><FontAwesomeIcon icon={faFacebookSquare} /></a>)}
                            {veterinario.Instagram && (<a target="_blank" rel="noreferrer" href={validURL(veterinario.Instagram) ? formatURL(veterinario.Instagram) : `https://instagram.com/${veterinario.Instagram.replace('@', '')}`} title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>)}
                        </div>
                    </div>
                    <a target="_blank" rel="noreferrer" title="Ir para o google maps." href={`https://www.google.com/maps/search/${encodeURIComponent(`${veterinario.Cidade} ${veterinario.Estado} - ${veterinario.Logradouro}, ${veterinario.Numero}`)}`} className="loc column">
                        <span>Bairro</span>
                        <span className="mb-5px text-muted">{veterinario.Bairro}</span>
                        <span>Logradouro</span>
                        <span className="mb-5px text-muted">{veterinario.Logradouro}, {veterinario.Numero}</span>
                    </a>
                </div>
                <div className="descricao">
                    <TextClamp title={veterinario.Descricao} text={veterinario.Descricao} lines="3" />
                </div>
            </div>
        )
    }
    )

    const resetForm = () => {
        estadoRef.current.selectedIndex = 0
        cidadeRef.current.value = ''
        bairroRef.current.value = ''
    }

    return (
        <>
            <Header />
            <main>
                <form className="card filter" onSubmit={e => { e.preventDefault(); getVeterinarios(); }}>
                    <div className="input-group">
                        <label htmlFor="estado">Estado:</label>
                        <select className="input-outline" id="estado" ref={estadoRef} name="estado" required defaultValue={user?.Estado}>
                            <option value="">Selecione um estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="cidade">Cidade:</label>
                        <input className="input-outline" ref={cidadeRef} type="text" name="cidade" id="cidade" defaultValue={user?.Cidade} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="bairro">Bairro:</label>
                        <input className="input-outline" ref={bairroRef} type="text" name="bairro" id="bairro" defaultValue={user?.Bairro} />
                    </div>
                    <div className="buttons">
                        <button className="btn" type="reset" onClick={e => { e.preventDefault(); resetForm() }}><FontAwesomeIcon icon={faTimes} /> Limpar Campos</button>
                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faSearch} /> Procurar</button>
                    </div>
                </form>

                <div className="veterinarios">
                    {loading ? <PulseLoader color="#65AAFF" /> : htmlData.length > 0 ? htmlData : <span>Não encontramos nada nesta região</span>}
                </div>
            </main>
        </>
    )
}
